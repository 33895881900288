// TODO: Move some or all of these to js-core?

import {
  percent,
  px,
  vh,
} from 'd2/utils/style'

// UNCATEGORIZED CONSTANTS
export const MAIN_NAV_BAR_HEIGHT = 70
export const BANNER_HEIGHT = 34
export const DAYS_TIL_DELETION = 30
export const MAX: string = percent(100)
export const MIN: string = percent(0)
export const SPA_ROOT = '/d2'
export const NONE = 'none'
export const FOOTER_LOGO_SIZE = 175

export const MODAL_WIDTH = 700
export const FILTER_MODAL_WIDTH = 550
export const SMARTLINK_MODAL_WIDTH = 600

export const SPACING_ZERO = 0 // 0
export const SPACING_EIGHTH = 2 // 2
export const SPACING_QUARTER: number = SPACING_EIGHTH * 2 // 4
export const SPACING_HALF: number = SPACING_QUARTER * 2 // 8
export const SPACING: number = SPACING_HALF * 2 // 16
export const SPACING_DOUBLE: number = SPACING * 2 // 32
export const SPACING_QUADRUPLE: number = SPACING_DOUBLE * 2 // 64
export const SPACING_OCTUPLE: number = SPACING_QUADRUPLE * 2 // 128

export const SPACING_MOBILE = 12

export const SIDENAV_LINE_HEIGHT = 1.25

export const SNACKBAR_WIDTH = 500

// AVATAR CONSTANTS
export const AVATAR_SIZE = 100
export const AVATAR_ICON_WRAPPER_SIZE = 36
export const AVATAR_ICON_FONT_SIZE = 20
export const AVATAR_ICON_IMAGE_SIZE = 22

export const AVATAR_LARGE_SIZE = 150
export const AVATAR_LARGE_ICON_WRAPPER_SIZE = 45
export const AVATAR_LARGE_ICON_FONT_SIZE = 22

export const AVATAR_SMALL_SIZE = 80
export const AVATAR_SMALL_ICON_WRAPPER_SIZE = 30
export const AVATAR_SMALL_ICON_FONT_SIZE = 16

export const AVATAR_EXTRA_SMALL_SIZE = 60
export const AVATAR_EXTRA_SMALL_ICON_WRAPPER_SIZE = 24
export const AVATAR_EXTRA_SMALL_ICON_FONT_SIZE = 12

// DATA TABLES
export const TINY_TABLE_SIZE = 5
export const SMALL_TABLE_SIZE = 10
export const PAGE_SIZE = 20

// the media.category for music videos
export const MUSIC_VIDEO = 'music'
// the media.category for web videos
export const WEB_VIDEO = 'web'

// the media.type for videos
export const VIDEO_MEDIA = 'video'
// the media.type for audio tracks
export const AUDIO_MEDIA = 'audio'
// the media.type for compositions
export const COMP_MEDIA = 'comp'

export const SONGWRITER_ROLE = 'songwriter'

// Card tab
export const CARD_TAB_INDICATOR_HEIGHT = 4
export const FILTER_BAR_CARD_TAB_MIN_HEIGHT = 70

// Picker Constants
export const PICKER_CONTAINER_BORDER = 1
export const PICKER_IMAGE_WIDTH = 50
export const PICKER_IMAGE_WIDTH_LARGE = 85
export const PICKER_CHECKBOX_ICON_SIZE = 20
export const PICKER_CONTAINER_BORDER_HIGHLIGHT = 2
export const PICKER_CONTAINER_BORDER_DISABLED = 2

export const ALBUM_THUMBNAIL_ICON_SIZE = 175

export const DESTINATION_LIST_ICON_WIDTH = 32

export const RADIO_OPTION_HEIGHT = 28
export const RADIO_HEIGHT = 24
export const RADIO_WIDTH = 48

export const DROP_DOWN_BUTTON_MIN_HEIGHT = 36

export const TRANSITION_SPEED = 300

export const SCROLLABLE_CONTAINER_MAX_HEIGHT = vh(60)
export const CARD_SEARCH_BAR_WIDTH = 240

// Donut Chart Constants
export const DONUT_SUMMARY_SIZE = 490

// Box Shadow
export const BOX_SHADOW = `0 ${px(3)} ${px(3)} 0 rgba(0,0,0,0.14), 0 ${px(3)} ${px(4)} 0 rgba(0,0,0,0.12), 0 ${px(1)} ${px(8)} 0 rgba(0,0,0,0.2)`

// Tooltip
export const TOOLTIP_LINE_HEIGHT = 20

export type ConnectableSocialsNetworksDeployerName = 'audiomack' | 'facebook' | 'instagram_business_account' | 'spotify' | 'twitter' | 'youtube'
export const CONNECTABLE_SOCIALS_NETWORKS_DEPLOYER_NAMES: ConnectableSocialsNetworksDeployerName[] = [
  'facebook',
  'instagram_business_account',
  'spotify',
  'twitter',
  'youtube',
  'audiomack',
]
export const SPOTIFY_DEPLOYER_NAME = 'spotify' as const

export const DEFAULT_SUPPORT_URL = 'http://support.vydia.com'
export const MAILTO_NEWLINE = '%0D%0A' // URL encoded line break for mailto links.
